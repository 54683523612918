import _ from 'lodash';

import { safeStringify as JsonStringify } from '@stoplight/json';
import { parseWithPointers } from '@stoplight/json/parseWithPointers';
import { stringify } from '@core/json';

import { startsWith } from '../objects';
import { pathToHash } from '../history';
import { cleanSlashes } from '../url';

import { move as _move } from './move';

export { default as shallowEqual } from './shallowEqual';

export const move = _move;

export const safeParse = (target, defaultValue) => {
  if (typeof target === 'string') {
    try {
      // if the number is parsed incorrectly return the original stringified num
      const num = parseNumber(target);
      if (typeof num === 'string') return num;

      return JSON.parse(target);
    } catch (e) {
      return !_.isUndefined(defaultValue) ? defaultValue : {};
    }
  }

  return target;
};

const parseNumber = string => {
  const numVal = _.toNumber(string);

  // For large number javascript maniuplates data, check if converted num is same as original
  if (_.isFinite(numVal)) {
    if (_.toString(numVal) === string) {
      return numVal;
    }

    return string;
  }

  return NaN;
};

export const safeStringify = (target, offset, iterator) => {
  return stringify(target, iterator, typeof offset !== 'undefined' ? offset : 2);
};

export const mapToNameValue = obj => {
  if (obj instanceof Array) {
    return obj;
  }

  return _.map(obj || {}, (value, name) => ({ name, value }));
};

export const nameValueToMap = nameValueArray => {
  if (!_.isArray(nameValueArray)) {
    return nameValueArray;
  }

  const result = {};
  for (const { name, value } of nameValueArray) {
    result[name] = value;
  }

  return result;
};

export const arrayIncludes = (parent, child) => {
  return startsWith(parent, child);
};

// given a JSON data object, and a propPath (array), return
// the line number range for the prop in the prettified JSON string.
export const computeLineNumbers = ({ data, propPath } = {}) => {
  let stringified = data;
  if (stringified && typeof stringified !== 'string') {
    stringified = JsonStringify(data, null, 2);
  }

  const numbers = {
    from: 0,
    to: 0,
  };

  if (_.isEmpty(stringified)) return numbers;

  const { pointers } = parseWithPointers(stringified);
  const pointer = getPointer(pointers, propPath);
  if (pointer) {
    numbers.from = _.get(pointer, 'start.line', 0);
    numbers.to = _.get(pointer, 'end.line', 0);
  }

  return numbers;
};

const getPointer = (pointers = {}, path = []) => {
  const pointerPath = cleanSlashes(pathToHash({ path }));
  const pointer = pointers[pointerPath];

  // if the pointer path does not exist, find the next highest in priority
  if (!pointer && path.length) {
    return getPointer(pointers, _.dropRight(path));
  }

  return pointer;
};

const calculatePathsForChildren = ({ parent = {}, parentPath = [] }) => {
  let paths = {};

  _.forEach(parent.children, (child, index) => {
    const childPath = parentPath.concat(['data', 'children', index]);

    paths[_.join(child.parsedPath, '.')] = childPath;

    const newPaths = calculatePathsForChildren({
      parent: child,
      parentPath: childPath,
    });

    paths = {
      ...paths,
      ...newPaths,
    };
  });

  return paths;
};

export const calculateFromToJsonPath = ({ fromPath = [], tree }) => {
  const toPaths = calculatePathsForChildren({ parent: tree });

  if (_.isArray(fromPath)) {
    return toPaths[_.join(fromPath, '.')];
  }

  return toPaths[fromPath];
};

export const escapeScriptTags = (json = '') => {
  const openScript = new RegExp('<script', 'g');
  const closeScript = new RegExp('</script', 'g');

  return json.replace(openScript, '<__script').replace(closeScript, '</__script');
};

export const unescapeScriptsTags = (json = '') => {
  const openScript = new RegExp('<__script', 'g');
  const closeScript = new RegExp('</__script', 'g');

  return json.replace(openScript, '<script').replace(closeScript, '</script');
};
